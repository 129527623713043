import React from 'react'



import "../CSS/Global/Global.css"



//importing files
import About from "./Home-Components/About"





//importing components 

export default function Home() {

    return (
        <div className='Home-container'>
            <About />
        </div>
    )
}
