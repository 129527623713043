/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 public/Desktop.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/Desktop.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube002.geometry} material={materials['Material.008']} position={[0, 0, -0.98]} scale={[2.5, 0.09, 0.82]} />
      <mesh geometry={nodes.Plane003.geometry} material={materials['Material.015']} position={[0, -1.51, -0.95]} scale={[3.9, 5.02, 1.99]} />
      <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.001']} position={[0.01, 0.14, -0.95]} rotation={[0, Math.PI / 2, 0]} scale={[0.55, 0.06, 0.75]} />
      <mesh geometry={nodes.Cube005.geometry} material={materials['Material.003']} position={[0, 0.13, -1.53]} scale={[0.06, 0.04, 0.08]} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials['Material.004']} position={[0, 0.18, -1.69]} scale={0.1} />
      <group position={[-0.02, 0.93, -1.46]} scale={[1.16, 0.47, 0.03]}>
        <mesh geometry={nodes.Cube008.geometry} material={materials['Material.005']} />
        <mesh geometry={nodes.Cube008_1.geometry} material={materials['Material.006']} />
      </group>
      <mesh geometry={nodes.Cylinder002.geometry} material={materials['Material.009']} position={[-1.9, 0.11, -1.53]} rotation={[0, 0.33, 0]} scale={[0.21, 0.03, 0.21]} />
      <mesh geometry={nodes.NurbsPath.geometry} material={materials['Material.010']} position={[-1.9, 0.48, -1.53]} rotation={[0, -0.42, -Math.PI / 2]} scale={[0.17, 1, 1]} />
      <group position={[-1.48, 0.85, -1.18]} rotation={[2.43, 0.07, -0.77]} scale={[0.14, 0.3, 0.14]}>
        <mesh geometry={nodes.Sphere005.geometry} material={materials['Material.011']} />
        <mesh geometry={nodes.Sphere005_1.geometry} material={materials['Material.012']} />
      </group>
      <mesh geometry={nodes.Cabe_hoe001.geometry} material={materials['Material.013']} position={[2.28, 0.09, -1.62]} scale={[0.12, -0.01, 0.12]} />
      <mesh geometry={nodes.Cube007.geometry} material={materials['Material.007']} position={[1.69, 0.1, -1.24]} rotation={[0, 1.07, 0]} scale={[0.27, -0.01, 0.41]} />
      <mesh geometry={nodes.Plane004.geometry} material={nodes.Plane004.material} position={[-1.27, 0.09, -0.87]} scale={[0.28, 0.31, 0.38]} />
      <mesh geometry={nodes.Plane005.geometry} material={nodes.Plane005.material} position={[-1.27, 0.09, -0.87]} rotation={[0, 0.16, 0]} scale={[0.28, 0.31, 0.38]} />
      <mesh geometry={nodes.Plane006.geometry} material={nodes.Plane006.material} position={[-1.27, 0.09, -0.87]} rotation={[0, -0.2, 0]} scale={[0.28, 0.31, 0.38]} />
      <mesh geometry={nodes['minimalism-4k-for-mac-desktop-wallpaper-preview'].geometry} material={materials['minimalism-4k-for-mac-desktop-wallpaper-preview']} position={[-0.02, 0.93, -1.45]} rotation={[Math.PI / 2, 0, 0]} scale={[1.27, 1, 0.89]} />
    </group>
  )
}

useGLTF.preload('/Desktop.glb')
