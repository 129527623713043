/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 public/Pickleball_badminton.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/Pickleball_badminton.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[-1.03, 1.01, 0]} rotation={[-0.65, -0.49, 0.51]} scale={0.11}>
        <mesh geometry={nodes.pCylinder10.geometry} material={materials.phong5} position={[-0.27, -0.01, -0.3]} rotation={[0.27, 0.24, 2.44]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder11.geometry} material={materials.phong5} position={[-0.26, 0.08, -0.3]} rotation={[0.33, 0.15, 2.12]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder12.geometry} material={materials.phong5} position={[-0.22, 0.16, -0.3]} rotation={[0.36, 0.04, 1.82]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder13.geometry} material={materials.phong5} position={[-0.15, 0.23, -0.3]} rotation={[0.35, -0.08, 1.52]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder14.geometry} material={materials.phong5} position={[-0.07, 0.27, -0.3]} rotation={[0.31, -0.19, 1.21]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder15.geometry} material={materials.phong5} position={[0.02, 0.29, -0.3]} rotation={[0.24, -0.27, 0.89]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder16.geometry} material={materials.phong5} position={[0.11, 0.27, -0.3]} rotation={[0.14, -0.34, 0.56]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder17.geometry} material={materials.phong5} position={[0.19, 0.23, -0.3]} rotation={[0.02, -0.36, 0.21]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder18.geometry} material={materials.phong5} position={[0.26, 0.16, -0.3]} rotation={[-0.1, -0.35, -0.13]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder19.geometry} material={materials.phong5} position={[0.3, 0.07, -0.3]} rotation={[-0.21, -0.3, -0.47]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder20.geometry} material={materials.phong5} position={[0.31, -0.02, -0.3]} rotation={[-0.29, -0.22, -0.79]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder21.geometry} material={materials.phong5} position={[0.29, -0.11, -0.3]} rotation={[-0.35, -0.12, -1.11]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder22.geometry} material={materials.phong5} position={[0.24, -0.19, -0.3]} rotation={[-0.37, 0, -1.41]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder4001.geometry} material={materials.phong5} position={[0.13, -0.26, -0.3]} rotation={[-0.33, 0.15, -1.85]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder5.geometry} material={materials.phong5} position={[0.04, -0.29, -0.3]} rotation={[-0.27, 0.25, -2.16]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder6.geometry} material={materials.phong5} position={[-0.05, -0.28, -0.3]} rotation={[-0.17, 0.32, -2.49]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder7.geometry} material={materials.phong5} position={[-0.13, -0.24, -0.3]} rotation={[-0.06, 0.36, -2.83]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder8.geometry} material={materials.phong5} position={[-0.2, -0.18, -0.3]} rotation={[0.06, 0.36, 3.11]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.pCylinder9.geometry} material={materials.phong5} position={[-0.25, -0.1, -0.3]} rotation={[0.18, 0.32, 2.77]} scale={[1.12, 1.05, 1.12]} />
        <mesh geometry={nodes.polySurface1.geometry} material={materials.lambert2} scale={[1.05, 1, 1.05]} />
        <mesh geometry={nodes.polySurface2.geometry} material={materials.lambert3} scale={[1.09, 1.04, 0.82]} />
        <mesh geometry={nodes.polySurface3.geometry} material={materials.phong4} scale={[1.05, 1, 1.05]} />
        <mesh geometry={nodes.pTorus1.geometry} material={materials.lambert1} position={[0, 0, -0.99]} scale={[0.83, 0.83, 0.46]} />
        <mesh geometry={nodes.pTorus2.geometry} material={materials.lambert1} position={[0, 0, -1.43]} scale={[0.93, 0.93, 0.52]} />
      </group>
      <group position={[0.48, 0.65, 0]} rotation={[0, 0, -0.56]} scale={[0.63, 0.63, 0.05]}>
        <mesh geometry={nodes.Cube010.geometry} material={materials['Material.003']} />
        <mesh geometry={nodes.Cube010_1.geometry} material={materials.Material} />
      </group>
      <mesh geometry={nodes.Cube006.geometry} material={materials['Material.003']} position={[-0.35, -0.68, 0]} rotation={[0, 0, -0.56]} scale={[0.11, 0.3, 0.3]} />
      <mesh geometry={nodes.Sphere_Sphere002.geometry} material={materials['Material.007']} position={[3.71, 0.3, -0.36]} rotation={[Math.PI / 2, 0, 0]} scale={0.08} />
    </group>
  )
}

useGLTF.preload('/Pickleball_badminton.glb')
