/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 public/Monitor.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/Monitor.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane.geometry} material={materials['Material.001']} position={[0, 0.24, 0.15]} rotation={[-0.13, 0, 0]} scale={[1.47, 8.25, 0.04]} />
      <group position={[0, 0.71, -0.23]} scale={[0.13, 0.72, 0.07]}>
        <mesh geometry={nodes.Cube004.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.Cube004_1.geometry} material={materials['Material.003']} />
      </group>
      <mesh geometry={nodes.gaming.geometry} material={materials.gaming} position={[0, 1, 0.08]} rotation={[1.44, 0, 0]} scale={[2.86, 1.05, 1.43]} />
    </group>
  )
}

useGLTF.preload('/Monitor.glb')
